import React from 'react';

import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Form, Button, Icon, Segment, Header } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  FileField,
  CourseDateTypeSelect,
  DateField,
  CountrySelect,
  CourseTopicSelect,
  CourseSubjectSelect,
  CourseVideoSelect,
  CourseTrainerSelect,
  BannersSelect,
  CheckboxField,
  RichTextAreaField,
  UpdateButton,
  Flex,
  ExternalLinkButton,
  PastEventSelect,
  UnsavedFormPrompt,
  YesNoSelect,
  EventTypeSelect,
  EventRegistrationTypeSelect,
  EventDurationTypeSelect,
  OpenModalButton,
  ErrorsAndButtonsInRow,
  ErrorsAndUpdateButtonInRow,
  DeleteModal
} from 'components';

import { PERMISSIONS, VisibleIf } from 'permissions';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import {
  buildCurrencyKey,
  buildDefaultCurrencyKey,
  preparePrices
} from 'pages/Course/utils';

import { AttendeeImportModal } from './components';
import { getInitialValues, courseFormSchema } from './utils';
import {
  courseUpdate,
  coursePublish,
  courseUnpublish,
  downloadCheckInCsvUrl,
  downloadAttendeeListCsvUrl
} from './sdk';

import { courseDelete } from './sdk';

import styles from './styles.module.css';

import { CourseList } from 'pages';

class CourseUpdateForm extends React.Component {
  onDefaultCurrencyChange = (selectedCurrency, setFieldValue) => {
    const { currencies } = this.props;
    setFieldValue(buildDefaultCurrencyKey(selectedCurrency), true);

    currencies.forEach(currency => {
      if (currency.id !== selectedCurrency.id) {
        setFieldValue(buildDefaultCurrencyKey(currency), false);
      }
    });
  };

  publishCourse = async () => {
    const { course, fetchCourse } = this.props;

    const { success, errors } = await coursePublish(course.id);

    if (success) {
      notifySuccess('Course published.');
      fetchCourse();
      return;
    }

    notifyErrors(errors);
  };

  unpublishCourse = async () => {
    const { course, fetchCourse } = this.props;

    const { success, errors } = await courseUnpublish(course.id);

    if (success) {
      notifySuccess('Course unpublished.');
      fetchCourse();
      return;
    }

    notifyErrors(errors);
  };

  handleSubmit = async (values, actions) => {
    const { course, fetchCourse } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const prices = preparePrices(values);

    if (!values.showPreviousAttendeeList) {
      values['previousEvents'] = [];
    }
    if (values.taxValue === '') {
      values.taxValue = null;
    }

    const data = prepareFormData(values);

    data.prices = prices;

    setSubmitting(true);

    const { errors, success } = await courseUpdate(course.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Course updated.');
      fetchCourse();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { course, currencies, user } = this.props;

    const isPublished = course.is_published;

    const initialValues = getInitialValues(course);

    currencies.forEach(currency => {
      const currencyValue = _.find(
        course.item.prices,
        price => price.currency.toString() === currency.id.toString()
      );

      initialValues[buildCurrencyKey(currency)] =
        (currencyValue && currencyValue.amount) || 0;

      initialValues[buildDefaultCurrencyKey(currency)] =
        currency.id.toString() === course.default_currency.toString();
    });

    return (
      <>
        <Flex flexEnd>
          <Segment
            compact
            color="grey"
            inverted
            style={{ padding: '0.5em', marginBottom: '0.5em' }}>
            <Flex>
              <div className={styles.actionsLabel}>Actions</div>
              <VisibleIf
                user={user}
                permissions={[PERMISSIONS.VIEW_EVENTS_EXPORTS]}>
                <ExternalLinkButton
                  style={{
                    paddingLeft: '0.7em',
                    paddingRight: '0.7em'
                  }}
                  url={downloadCheckInCsvUrl(course.id)}>
                  <Icon name="download" />
                  Check-in
                </ExternalLinkButton>
                <ExternalLinkButton
                  style={{
                    paddingLeft: '0.7em',
                    paddingRight: '0.7em'
                  }}
                  url={downloadAttendeeListCsvUrl(course.id)}>
                  <Icon name="download" />
                  Attendees
                </ExternalLinkButton>
              </VisibleIf>

              {!isPublished && (
                <Button color="green" onClick={this.publishCourse}>
                  Publish
                </Button>
              )}
              {isPublished && (
                <Button color="yellow" onClick={this.unpublishCourse}>
                  Unpublish
                </Button>
              )}
              <OpenModalButton
                size="medium"
                color="green"
                modal={{
                  header: 'Import Attendees',
                  render: props => (
                    <AttendeeImportModal courseId={course.id} {...props} />
                  )
                }}>
                Import Attendees
              </OpenModalButton>
            </Flex>
          </Segment>
        </Flex>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={courseFormSchema}
          onSubmit={this.handleSubmit}>
          {({
            values,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            dirty,
            errors,
            touched
          }) => (
            <>
              <Form>
                <UnsavedFormPrompt when={dirty} />
                <ErrorsAndUpdateButtonInRow
                  touched={touched}
                  errors={errors}
                  subject="Event"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                />

                <FormRow>
                  <Field
                    width={4}
                    name="title"
                    label="Title"
                    required
                    component={InputField}
                  />
                  <Field
                    width={4}
                    name="slug"
                    label="Slug"
                    required
                    component={InputField}
                  />
                  <EventTypeSelect
                    width={4}
                    name="eventType"
                    label="Event Type"
                    required
                  />
                  <YesNoSelect
                    width={4}
                    name="isRegistrationOpen"
                    label="Registration Status"
                    required
                    yesLabel="Open"
                    noLabel="Closed"
                    isClearable={false}
                  />
                </FormRow>
                <FormRow alignEnd>
                  <Field
                    width={4}
                    name="titleWeb"
                    label="Event Name"
                    required
                    component={InputField}
                  />
                  <Field
                    width={3}
                    name="duration"
                    label="Duration"
                    required
                    component={InputField}
                  />
                  <EventDurationTypeSelect
                    width={3}
                    name="durationType"
                    label="Duration Dimension"
                    required
                    isClearable={false}
                  />
                  <YesNoSelect
                    width={2}
                    name="isFree"
                    label="Is Free"
                    required
                    isClearable={false}
                  />
                  <EventRegistrationTypeSelect
                    width={3}
                    name="registrationType"
                    label="Registration Type"
                    required
                    isClearable={false}
                  />
                  {values['registrationType'] &&
                    values['registrationType'] === 'Custom' && (
                      <Field
                        component={InputField}
                        name="customRegistrationUrl"
                        width={4}
                        label="Custom Registration Url"
                        required
                      />
                    )}
                </FormRow>
                <FormRow>
                  <YesNoSelect
                    width={3}
                    name="guestRegistrationsEnabled"
                    label="Enable guest registrations"
                    required
                    isClearable={false}
                  />
                </FormRow>
                <FormRow widths="equal">
                  <Field name="image" label="Image" component={FileField} />
                  <Field name="file" label="Prospectus" component={FileField} />
                  <Field
                    name="applicationFile"
                    label="Application File"
                    component={FileField}
                  />
                </FormRow>

                <FormRow widths="equal">
                  <Field
                    name="sponsorship"
                    label="Sponsorship"
                    component={FileField}
                  />
                  <Field
                    name="hotelBookingForm"
                    label="Hotel Booking Form"
                    component={FileField}
                  />
                  <Field
                    name="hotelBookingLink"
                    label="Hotel Booking Link"
                    component={InputField}
                  />
                </FormRow>

                <FormRow>
                  <Field
                    width={4}
                    name="dateType"
                    label="Date Type"
                    component={CourseDateTypeSelect}
                    isClearable={false}
                    onChange={this.onCourseDateTypeChange}
                  />
                  <Field
                    width={4}
                    name="dateCustom"
                    label="Custom Date"
                    component={InputField}
                    disabled={values.dateType === 'Fixed Date'}
                  />
                </FormRow>
                <FormRow>
                  <Field
                    width={4}
                    name="dateStart"
                    label="Start date"
                    component={DateField}
                  />
                  <Field
                    width={4}
                    name="dateActivation"
                    label="Date of Activation"
                    component={DateField}
                  />
                </FormRow>

                <FormRow>
                  <Field
                    width={4}
                    name="country"
                    label="Country"
                    component={CountrySelect}
                  />
                  <Field
                    width={4}
                    name="city"
                    label="City"
                    component={InputField}
                  />
                </FormRow>
                <FormRow>
                  <Field
                    width={4}
                    name="topic"
                    label="Topic"
                    component={CourseTopicSelect}
                  />
                  <Field
                    width={4}
                    name="subject"
                    label="Subject"
                    component={CourseSubjectSelect}
                  />
                </FormRow>
                <div className={styles.currencyRow}>
                  {currencies.map(currency => (
                    <div key={currency.id}>
                      <Field
                        name={buildCurrencyKey(currency)}
                        label={`Price in ${currency.code}`}
                        component={InputField}
                      />
                      <Field
                        name={buildDefaultCurrencyKey(currency)}
                        label="Default currency"
                        component={CheckboxField}
                        onChange={() =>
                          this.onDefaultCurrencyChange(currency, setFieldValue)
                        }
                      />
                    </div>
                  ))}
                </div>
                <FormRow>
                  <Field
                    width={4}
                    name="taxName"
                    label="Tax name"
                    component={InputField}
                  />
                  <Field
                    width={4}
                    name="taxValue"
                    label="Tax value(%)"
                    component={InputField}
                    type="number"
                  />
                </FormRow>
                <FormRow>
                  <Field
                    width={4}
                    name="videoTitle"
                    label="Video Title"
                    component={InputField}
                  />
                  <Field
                    width={4}
                    name="video"
                    label="Video"
                    component={CourseVideoSelect}
                  />
                </FormRow>
                <Field
                  width={8}
                  name="trainersTitle"
                  label="Speakers Title"
                  component={InputField}
                />
                <CourseTrainerSelect
                  name="trainers"
                  label="Speakers"
                  isMulti
                  enableOrdering={true}
                />

                <FormRow widths="equal">
                  <Field
                    name="trainersTextOnTop"
                    label="Speakers text on top"
                    component={RichTextAreaField}
                  />

                  <Field
                    name="trainersTextOnBottom"
                    label="Speakers text on bottom"
                    component={RichTextAreaField}
                  />
                </FormRow>

                <FormRow widths="equal">
                  <Field
                    name="agendaTextOnTop"
                    label="Agenda text on top"
                    component={RichTextAreaField}
                  />

                  <Field
                    name="agendaTextOnBottom"
                    label="Agenda text on bottom"
                    component={RichTextAreaField}
                  />
                </FormRow>

                <Field
                  width={16}
                  name="attendeesText"
                  label="Attendees Tab"
                  component={RichTextAreaField}
                />
                <FormRow>
                  <Field
                    width={3}
                    component={CheckboxField}
                    name="showCurrentAttendeeList"
                    label="Make current attendee list live"
                  />
                  <Field
                    width={3}
                    component={CheckboxField}
                    name="showPreviousAttendeeList"
                    label="Show previous attendee lists"
                  />
                </FormRow>
                {values.showPreviousAttendeeList && (
                  <PastEventSelect
                    width={6}
                    name="previousEvents"
                    label="Select previous events"
                    isMulti={true}
                  />
                )}
                <FormRow>
                  <Field
                    name="beforeAttendeesText"
                    label="Before attendees text"
                    component={RichTextAreaField}
                  />
                  <Field
                    name="afterAttendeesText"
                    label="After attendees text"
                    component={RichTextAreaField}
                  />
                </FormRow>
                <FormRow>
                  <BannersSelect
                    name="banners"
                    label="Banners"
                    isMulti
                    enableOrdering={true}
                  />
                </FormRow>
                <ErrorsAndButtonsInRow errors={errors} touched={touched}>
                  <Flex>
                    {!isPublished && (
                      <Button
                        size="large"
                        color="green"
                        onClick={this.publishCourse}>
                        Publish
                      </Button>
                    )}
                    {isPublished && (
                      <Button
                        size="large"
                        color="yellow"
                        onClick={this.unpublishCourse}>
                        Unpublish
                      </Button>
                    )}
                    <UpdateButton
                      subject="Event"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    />
                  </Flex>
                </ErrorsAndButtonsInRow>
                {user.is_superuser && (
                  <Segment color="red">
                    <Header as="h3">Dangerous Actions</Header>
                    <DeleteModal
                      itemId={course.id}
                      objName={course.title}
                      deleteButtonText="Delete Event"
                      sdk={courseDelete}
                      successRedirect={CourseList}
                    />
                  </Segment>
                )}
              </Form>
            </>
          )}
        </Formik>
      </>
    );
  }
}

export default withRouter(CourseUpdateForm);
