import { useEffect } from 'react';

import { notifyErrors, notifySuccess } from 'utils/notifications';

import { industryEventsDelete } from './sdk';

const DeleteEventModal = ({ selectedEvents, onSuccess, onClose }) => {
  useEffect(() => {
    const handleDelete = async () => {
      const result = window.confirm(
        'Are you sure you want to delete the selected events?'
      );

      if (!result) {
        onClose();
        return;
      }

      const { errors, success } = await industryEventsDelete({
        industry_events: selectedEvents
      });

      if (success) {
        notifySuccess('Industry Events successfully deleted.');
        onSuccess();
      } else {
        notifyErrors(errors);
        onClose();
      }
    };

    handleDelete();
  }, [selectedEvents, onSuccess, onClose]);

  return null; // We only show the browser's confirmation window
};

export default DeleteEventModal;
