import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Table } from 'semantic-ui-react';

import { Admin, SortableHeader, TableFooter, YesNo } from 'components';
import { pageUrl } from 'config/routes';
import { formatDate } from 'utils';
import { buildFilters } from 'utils/filters';
import { IndustryEventDetail } from 'pages';

import { industryEventIdList } from './sdk';
import BulkActionsModal from './components/BulkActionsModal';

const IndustryEventTable = ({
  industryEvents,
  sort,
  orderBy,
  pagination,
  refetch,
  count,
  filters
}) => {
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectAllGlobal, setSelectAllGlobal] = useState(false);

  const toggleEventSelect = (data, eventId) => {
    const { checked } = data;

    if (checked) {
      setSelectedEvents([...selectedEvents, eventId]);
    } else {
      setSelectedEvents(selectedEvents.filter(id => id !== eventId));
    }
  };

  const toggleSelectAllOnPage = data => {
    const { checked } = data;

    if (checked) {
      setSelectedEvents(industryEvents.map(event => event.id));
    } else {
      setSelectedEvents([]);
    }
  };

  const toggleSelectAllGlobal = async () => {
    if (!selectAllGlobal) {
      const { data, success } = await industryEventIdList(
        buildFilters({ ...filters })
      );

      if (success) {
        setSelectedEvents(data);
        setSelectAllGlobal(true);
      }
    } else {
      setSelectedEvents([]);
      setSelectAllGlobal(false);
    }
  };

  const updateOnSuccess = useCallback(() => {
    setSelectedEvents([]);
    refetch();
  }, [refetch]);

  return (
    <>
      <Button
        onClick={toggleSelectAllGlobal}
        style={{
          backgroundColor: selectAllGlobal ? 'red' : 'rgb(36, 214, 163)',
          color: 'white'
        }}>
        {selectAllGlobal
          ? `Unselect all ${count} events`
          : `Select all ${count} events`}
      </Button>
      <BulkActionsModal
        selectedEvents={selectedEvents}
        updateOnSuccess={updateOnSuccess}
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="9">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>
              <Checkbox
                checked={selectedEvents.length === industryEvents.length}
                onChange={(event, data) => toggleSelectAllOnPage(data)}
              />
            </Table.HeaderCell>
            <SortableHeader sort={sort} orderBy={orderBy} field="title">
              Title
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="is_published">
              Is published
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="start_date">
              Start date
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="end_date">
              End date
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
              Created by
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
              Created at
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
              Updated by
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
              Updated at
            </SortableHeader>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {industryEvents.map(course => (
            <Table.Row key={course.id}>
              <Table.Cell>
                <Checkbox
                  checked={selectedEvents.includes(course.id)}
                  onChange={(event, data) => toggleEventSelect(data, course.id)}
                />
              </Table.Cell>
              <Table.Cell width={4}>
                <Link to={pageUrl(IndustryEventDetail, { eventId: course.id })}>
                  {course.title}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <YesNo value={course.is_published} />
              </Table.Cell>
              <Table.Cell>{formatDate(course.start_date)}</Table.Cell>
              <Table.Cell>{formatDate(course.end_date)}</Table.Cell>
              <Table.Cell>
                <Admin value={course.created_by} />
              </Table.Cell>
              <Table.Cell>{formatDate(course.created_at)}</Table.Cell>
              <Table.Cell>
                <Admin value={course.updated_by} />
              </Table.Cell>
              <Table.Cell>{formatDate(course.updated_at)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    </>
  );
};

export default IndustryEventTable;
