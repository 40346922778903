import { DateField, InputField, YesNoSelect } from 'components';

export const FILTERS = [
  [
    {
      name: 'title',
      emptyValue: '',
      component: InputField,
      label: 'Title',
      width: 7
    },
    {
      name: 'start_date',
      emptyValue: null,
      component: DateField,
      label: 'Start date',
      width: 4
    },
    {
      name: 'end_date',
      emptyValue: null,
      component: DateField,
      label: 'End date',
      width: 4
    }
  ],
  [
    {
      name: 'is_published',
      emptyValue: null,
      fieldComponent: YesNoSelect,
      label: 'Is published',
      width: 3
    }
  ]
];
