import { useCallback } from 'react';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import IndustryEventForm from 'components/IndustryEventForm';

import { industryEventUpdate } from './sdk';

const IndustryEventUpdateForm = ({ industryEvent, fetchIndustryEvent }) => {
  const handleSubmit = useCallback(
    async (values, actions) => {
      const { setSubmitting, setFieldError } = actions;

      setSubmitting(true);
      const data = prepareFormData(values);

      const { errors, success } = await industryEventUpdate(
        industryEvent.id,
        data
      );
      setSubmitting(false);

      if (success) {
        notifySuccess('Industry Event successfully updated.');
        fetchIndustryEvent();
        return;
      }

      handleFormErrors(errors, setFieldError);
    },
    [industryEvent, fetchIndustryEvent]
  );

  const initialValues = {
    title: industryEvent.title,
    description: industryEvent.description,
    image: industryEvent.image,
    startDate: industryEvent.start_date,
    endDate: industryEvent.end_date,
    isPublished: industryEvent.is_published,
    externalUrl: industryEvent.external_url,
    topic: industryEvent.topic,
    city: industryEvent.city,
    country: industryEvent.country
  };

  return (
    <IndustryEventForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    />
  );
};

export default IndustryEventUpdateForm;
