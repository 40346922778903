import moment from 'moment';
import _ from 'lodash';

import {
  InvoiceList,
  ClientList,
  ClientSuppressedList,
  ClientReminderList,
  OrderList,
  Dashboard,
  EmailDashboard,
  SentimentHeatMapDetail,
  SubscriptionList,
  BannerList,
  PollList,
  TrainerList,
  VideoGalleryList,
  MailshotList,
  EmailTemplateList,
  CourseList,
  MarketDataReportList,
  WeeklySteelList,
  WeeklyPowerMaterialsList,
  WeeklySteelOpenCount,
  WeeklySeasmOpenCount,
  TradeExcelList,
  StaffList,
  LoginAttemptList,
  GroupsAndPermissionsList,
  ProductList,
  CountryList,
  CurrencyRateList,
  EventsDashboard,
  InvoiceEmailLogList,
  AutoCustomEmailLogList,
  MailshotEmailLogList,
  WeeklySteelEmailLogList,
  RenewalsDashboard,
  LinkedClientsList,
  PageList,
  GlossaryCategoryList,
  GlossaryTermList,
  RegistrationAttemptList,
  GeneralPipelinesDashboard,
  PersonalPipelineDashboard,
  InstantNewsEmailLogList,
  InvoicesDashboard,
  ClientLoginInformationList,
  CorporateRssFeedAccessLog,
  TrackedPriceSeries,
  ExchangeRates,
  BudgetDashboard,
  TrackingSubscriptionTrialEvents,
  SEASMEditionList,
  WebArticleList,
  PriceSerieList,
  PriceSerieMissingDataList,
  PriceSerieMovementList,
  PriceSerieUpdate,
  PriceSeriePublish,
  NewsletterList,
  NewsletterSetup,
  RecipientGroups,
  ClientProfileLogList,
  FeedbackList,
  SalesTerritoryList,
  FaqList,
  FaqCategoryList,
  PriceSpreadV2List,
  PriceSpreadV2Movement,
  CompanyV2List,
  DailyNewsletterEmailLogListV2,
  ArticleChangesList,
  ArticleChangesAnalyticsList,
  OnlineUsersList,
  PriceIndexList,
  UnitConversionRateList,
  TrackedArticlesList,
  MailshotGroupList,
  WeeklyPowerMaterialsEmailLogList,
  WeeklyPowerMaterialsOpenCount,
  SteelPulseMenaEditionList,
  SteelPulseMenaOpenCount,
  SteelPulseMenaEmailLogList,
  SteelIntelligenceChinaEditionList,
  SteelIntelligenceChinaOpenCount,
  SteelIntelligenceIndiaEditionList,
  SteelIntelligenceIndiaOpenCount,
  SteelIntelligenceIndiaEmailLogList,
  SteelIntelligenceChinaEmailLogList,
  IndustryEventList
} from 'pages';
import { BASE_URL, BASE_BACKEND_URL } from 'sdk/urls';
import { openInNewTab, formatDateForApi } from 'utils';
import { PERMISSIONS, hasHacksoftPermission } from 'permissions';
import { getProductName } from 'utils';
import {
  STEEL_SLUG,
  POWER_MATERIAL_SLUG,
  HYDROGEN_SLUG
} from 'domainConstants';

import { getToday } from 'utils/forms';
import { SUBSCRIBER_STATUS_ID } from 'pages/Client/constants';

const TODAY = getToday();

export const getMenuItems = (
  user,
  pendingForTrialCount,
  partialTrialCount,
  trialCount
) => {
  const menuItems = [
    {
      title: 'Dashboards',
      items: [
        {
          title: 'General',
          link: Dashboard
        },
        { title: 'Email Dashboard', link: EmailDashboard },
        { title: 'Events Dashboard', link: EventsDashboard },
        { title: 'Renewals Dashboard', link: RenewalsDashboard },
        {
          title: 'General Pipeline Dashboard',
          link: GeneralPipelinesDashboard
        },
        {
          title: 'Personal Pipeline Dashboard',
          link: PersonalPipelineDashboard
        },
        {
          title: 'Income Sheet',
          link: InvoicesDashboard,
          queryParams: `invoice_paid_month=${new Date().getMonth() +
            1}&invoice_paid_year=${new Date().getFullYear()}`
        },
        {
          title: 'Budget',
          link: BudgetDashboard,
          queryParams: `year=${new Date().getFullYear()}`
        },
        {
          title: 'Forms Dashboard',
          link: TrackingSubscriptionTrialEvents,
          queryParams: `month=${new Date().getMonth() +
            1}&year=${new Date().getFullYear()}`
        }
      ]
    },
    {
      title: 'CMS',
      items: [
        {
          title: 'Weekly Steel Heat Map',
          link: SentimentHeatMapDetail
        },
        {
          title: 'File Manager',
          link: null,
          action: () => openInNewTab(`${BASE_URL}/auth/from-dashboard/filer/`)
        },
        {
          title: 'Vendors',
          link: null,
          action: () => openInNewTab(`${BASE_URL}/auth/from-dashboard/vendors/`)
        },
        {
          title: 'Subscriptions',
          link: SubscriptionList
        },
        {
          title: 'Banners',
          link: BannerList
        },
        {
          title: 'Feedback',
          link: FeedbackList
        },
        {
          title: 'Page Manager',
          link: PageList
        },
        {
          title: 'Polls',
          link: PollList
        },
        {
          title: 'Speakers',
          link: TrainerList
        },
        {
          title: 'Video Gallery',
          link: VideoGalleryList
        },
        {
          title: 'Price Series Products',
          link: ProductList
        },
        {
          title: 'Companies',
          link: CompanyV2List
        },
        {
          title: 'Countries',
          link: CountryList
        },
        {
          title: 'Glossary Terms',
          link: GlossaryTermList
        },
        {
          title: 'Glossary Categories',
          link: GlossaryCategoryList
        },
        {
          title: 'FAQs',
          link: FaqList
        },
        {
          title: 'FAQ Categories',
          link: FaqCategoryList
        }
      ]
    },
    {
      title: 'CRM',
      items: [
        {
          title: 'Clients',
          link: ClientList,
          permissions: [PERMISSIONS.VIEW_CLIENTS]
        },
        {
          title: `Pending for Trial (${pendingForTrialCount})`,
          link: ClientList,
          permissions: [PERMISSIONS.VIEW_CLIENTS],
          queryParams: 'subscription_status=1'
        },
        {
          title: `Partial Trial (${partialTrialCount})`,
          link: ClientList,
          permissions: [PERMISSIONS.VIEW_CLIENTS],
          queryParams: 'subscription_status=16'
        },
        {
          title: `Trial/Short Trial (${trialCount})`,
          link: ClientList,
          permissions: [PERMISSIONS.VIEW_CLIENTS],
          queryParams: 'subscription_status=2,3'
        },
        {
          title: 'Suppressed',
          link: ClientSuppressedList,
          permissions: [PERMISSIONS.VIEW_CLIENTS]
        },

        {
          title: 'My Suppressed',
          link: ClientSuppressedList,
          queryParams: `account_manager=${user.id}`,
          permissions: [PERMISSIONS.VIEW_CLIENTS]
        },
        {
          title: 'Linked Clients',
          link: LinkedClientsList,
          permissions: [PERMISSIONS.VIEW_CLIENTS]
        },
        {
          title: 'Invoices',
          link: InvoiceList,
          permissions: [PERMISSIONS.VIEW_INVOICES]
        },
        {
          title: 'Client Reminders',
          link: ClientReminderList,
          permissions: [PERMISSIONS.VIEW_CLIENTS]
        },
        {
          title: 'Orders',
          link: OrderList,
          permissions: [PERMISSIONS.VIEW_ORDERS]
        },
        {
          title: 'Registration Attempts',
          link: RegistrationAttemptList
        },
        {
          title: 'Bank Accounts',
          link: null,
          action: () =>
            openInNewTab(`${BASE_BACKEND_URL}/admin/crm/bankaccount/`)
        },
        {
          title: 'Corporate RSS Access Log',
          link: CorporateRssFeedAccessLog,
          permissions: [PERMISSIONS.VIEW_CORPORATE_RSS_LOGS]
        }
      ]
    },
    {
      title: 'Emails',
      items: [
        {
          title: 'Mailshots',
          link: MailshotList,
          permissions: [PERMISSIONS.VIEW_MAILSHOTS]
        },
        {
          title: 'Mailshot Groups',
          link: MailshotGroupList,
          permissions: [PERMISSIONS.VIEW_MAILSHOTS]
        },
        {
          title: 'Email Templates',
          link: EmailTemplateList,
          permissions: [PERMISSIONS.VIEW_MAILSHOTS]
        },
        {
          title: 'Invoice Archive',
          link: InvoiceEmailLogList
        },
        {
          title: 'Auto Email Archive',
          link: AutoCustomEmailLogList
        },
        {
          title: 'Breaking news/Trending now Archive',
          link: InstantNewsEmailLogList
        },
        {
          title: 'Mailshot Archive',
          link: MailshotEmailLogList
        },
        {
          title: 'Weekly Steel Archive',
          link: WeeklySteelEmailLogList
        },
        {
          title: 'Weekly Steel Open Count',
          link: WeeklySteelOpenCount
        },
        {
          title: 'Weekly SEASM Open Count',
          link: WeeklySeasmOpenCount
        },
        {
          title: 'Weekly Power Materials Archive',
          link: WeeklyPowerMaterialsEmailLogList
        },
        {
          title: 'Weekly Power Materials Open Count',
          link: WeeklyPowerMaterialsOpenCount
        },
        {
          title: 'Steel Pulse MENA Archive',
          link: SteelPulseMenaEmailLogList
        },
        {
          title: 'Steel Pulse MENA Open Count',
          link: SteelPulseMenaOpenCount
        },
        {
          title: 'Steel Intelligence China Archive',
          link: SteelIntelligenceChinaEmailLogList
        },
        {
          title: 'Steel Intelligence China Open Count',
          link: SteelIntelligenceChinaOpenCount
        },
        {
          title: 'Steel Intelligence India Archive',
          link: SteelIntelligenceIndiaEmailLogList
        },
        {
          title: 'Steel Intelligence India Open Count',
          link: SteelIntelligenceIndiaOpenCount
        }
      ]
    },
    {
      title: `${getProductName(STEEL_SLUG)} Editorial`,
      items: [
        {
          title: 'Newsletters',
          link: NewsletterList,
          permissions: [PERMISSIONS.VIEW_NEWSLETTERS],
          params: { productSlug: STEEL_SLUG }
        },
        {
          title: 'Articles',
          link: WebArticleList,
          permissions: [PERMISSIONS.VIEW_ARTICLES],
          params: { productSlug: STEEL_SLUG }
        },
        {
          title: 'Weekly Steel',
          link: WeeklySteelList,
          permissions: [PERMISSIONS.VIEW_WEEKLY_STEEL]
        },
        {
          title: 'Steel Intelligence China',
          link: SteelIntelligenceChinaEditionList,
          permissions: [PERMISSIONS.VIEW_STEEL_INTELLIGENCE_CHINA_REPORT]
        },
        {
          title: 'Steel Intelligence India',
          link: SteelIntelligenceIndiaEditionList,
          permissions: [PERMISSIONS.VIEW_STEEL_INTELLIGENCE_INDIA_REPORT]
        },
        {
          title: 'Steel Pulse MENA',
          link: SteelPulseMenaEditionList,
          permissions: [PERMISSIONS.VIEW_STEEL_PULSE_MENA_REPORT]
        },
        {
          title: 'Price Series Setup',
          link: PriceSerieList,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: STEEL_SLUG }
        },
        {
          title: 'Price Series Update',
          link: PriceSerieUpdate,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: STEEL_SLUG }
        },
        {
          title: 'Price Series Publish',
          link: PriceSeriePublish,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: STEEL_SLUG }
        },
        {
          title: 'Global Tariffs Data',
          link: TradeExcelList
        },
        {
          title: 'Currency Rates',
          link: CurrencyRateList,
          queryParams: `date_from=${formatDateForApi(
            moment().startOf('month')
          )}&date_to=${formatDateForApi(moment().endOf('month'))}`
        },
        {
          title: 'Price Series Movement',
          link: PriceSerieMovementList,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: STEEL_SLUG }
        },
        {
          title: 'Price Series Missing Data',
          link: PriceSerieMissingDataList,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: STEEL_SLUG }
        },
        {
          title: 'Price Spread Setup',
          link: PriceSpreadV2List,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: STEEL_SLUG }
        },
        {
          title: 'Price Spread Movement',
          link: PriceSpreadV2Movement,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: STEEL_SLUG }
        },
        {
          title: 'Daily Newsletter Archive',
          link: DailyNewsletterEmailLogListV2,
          permissions: [PERMISSIONS.VIEW_NEWSLETTERS],
          params: { productSlug: STEEL_SLUG },
          // All logs for today, opened more than 10 times by subscribers, ordered by the most opens on top.
          queryParams: `start_date=${TODAY}&end_date=${TODAY}&opened=True&subscription_status=${SUBSCRIBER_STATUS_ID}&opens_from_count=10&order=-statistic_logs_count`
        },
        {
          title: 'SEASM Reports',
          link: SEASMEditionList,
          // TODO: change permissions
          permissions: [PERMISSIONS.VIEW_WEEKLY_STEEL]
        },
        {
          title: 'Setup Newsletter',
          link: NewsletterSetup,
          permissions: [PERMISSIONS.VIEW_NEWSLETTERS],
          params: { productSlug: STEEL_SLUG, newsletterType: 'daily' },
          superUserRequired: true,
          disabled: !hasHacksoftPermission(user)
        },
        {
          title: 'Recipient Groups',
          link: RecipientGroups,
          permissions: [PERMISSIONS.VIEW_NEWSLETTERS],
          params: { productSlug: STEEL_SLUG, newsletterType: 'daily' },
          superUserRequired: true,
          disabled: !hasHacksoftPermission(user)
        },
        {
          title: 'Price Indexes',
          link: PriceIndexList,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: STEEL_SLUG }
        }
      ]
    },
    {
      title: getProductName(POWER_MATERIAL_SLUG),
      items: [
        {
          title: 'Newsletters',
          link: NewsletterList,
          permissions: [PERMISSIONS.VIEW_NEWSLETTERS],
          params: { productSlug: POWER_MATERIAL_SLUG }
        },
        {
          title: 'Articles',
          link: WebArticleList,
          permissions: [PERMISSIONS.VIEW_ARTICLES],
          params: { productSlug: POWER_MATERIAL_SLUG }
        },
        {
          title: 'Weekly Power Materials',
          link: WeeklyPowerMaterialsList,
          permissions: [PERMISSIONS.VIEW_WEEKLY_POWER_MATERIALS]
        },
        {
          title: 'Price Series Setup',
          link: PriceSerieList,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: POWER_MATERIAL_SLUG }
        },
        {
          title: 'Price Series Update',
          link: PriceSerieUpdate,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: POWER_MATERIAL_SLUG }
        },
        {
          title: 'Price Series Publish',
          link: PriceSeriePublish,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: POWER_MATERIAL_SLUG }
        },
        {
          title: 'Price Series Movement',
          link: PriceSerieMovementList,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: POWER_MATERIAL_SLUG }
        },
        {
          title: 'Price Series Missing Data',
          link: PriceSerieMissingDataList,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: POWER_MATERIAL_SLUG }
        },
        {
          title: 'Price Spread Setup',
          link: PriceSpreadV2List,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: POWER_MATERIAL_SLUG }
        },
        {
          title: 'Price Spread Movement',
          link: PriceSpreadV2Movement,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: POWER_MATERIAL_SLUG }
        },
        {
          title: 'Daily Newsletter Archive',
          link: DailyNewsletterEmailLogListV2,
          permissions: [PERMISSIONS.VIEW_NEWSLETTERS],
          params: { productSlug: POWER_MATERIAL_SLUG }
        },
        {
          title: 'Setup Newsletter',
          link: NewsletterSetup,
          permissions: [PERMISSIONS.VIEW_NEWSLETTERS],
          params: { productSlug: POWER_MATERIAL_SLUG, newsletterType: 'daily' },
          superUserRequired: true,
          disabled: !hasHacksoftPermission(user)
        },
        {
          title: 'Recipient Groups',
          link: RecipientGroups,
          permissions: [PERMISSIONS.VIEW_NEWSLETTERS],
          params: { productSlug: POWER_MATERIAL_SLUG, newsletterType: 'daily' },
          superUserRequired: true,
          disabled: !hasHacksoftPermission(user)
        },
        {
          title: 'Price Indexes',
          link: PriceIndexList,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: POWER_MATERIAL_SLUG }
        }
      ]
    },
    {
      title: getProductName(HYDROGEN_SLUG),
      items: [
        {
          title: 'Price Series Setup',
          link: PriceSerieList,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: HYDROGEN_SLUG }
        },
        {
          title: 'Price Series Update',
          link: PriceSerieUpdate,
          permissions: [PERMISSIONS.VIEW_PRICES],
          params: { productSlug: HYDROGEN_SLUG }
        }
      ]
    },
    {
      title: 'Admin Tools',
      items: [
        {
          title: 'Groups and permissions',
          link: GroupsAndPermissionsList
        },
        {
          title: 'Staff Members',
          link: StaffList,
          queryParams: 'is_active=true',
          permissions: [PERMISSIONS.VIEW_STAFF]
        },
        {
          title: 'Admin Login Attempts',
          link: LoginAttemptList
        },
        {
          title: 'Website Login Attempts',
          link: ClientLoginInformationList,
          permissions: [PERMISSIONS.VIEW_CLIENTS]
        },
        {
          title: 'Tracked Price Series',
          link: TrackedPriceSeries,
          permissions: [PERMISSIONS.VIEW_CLIENTS]
        },
        {
          title: 'Tracked Articles Opens',
          link: TrackedArticlesList,
          permissions: [PERMISSIONS.VIEW_CLIENTS]
        },
        {
          title: 'Exchange Currency Rates',
          link: ExchangeRates,
          queryParams: `date_to=${formatDateForApi(moment())}&currency=EUR`
        },
        {
          title: 'Unit Conversion Rates',
          link: UnitConversionRateList
        },
        {
          title: 'Client Profile Logs',
          link: ClientProfileLogList
        },
        {
          title: 'Sales Territories',
          link: SalesTerritoryList
        },
        {
          title: 'Article Changes',
          link: ArticleChangesList,
          permissions: [PERMISSIONS.PUBLISH_ARTICLES]
        },
        {
          title: 'Article Changes Analytics',
          link: ArticleChangesAnalyticsList,
          queryParams: `start=${formatDateForApi(
            moment().startOf('month')
          )}&end=${formatDateForApi(moment().endOf('month'))}`,
          permissions: [PERMISSIONS.PUBLISH_ARTICLES]
        },
        {
          title: "Who's Online",
          link: OnlineUsersList,
          superUserRequired: true
        }
      ]
    },
    {
      title: 'Events',
      items: [
        {
          title: 'Kallanish Events',
          link: CourseList
        },
        {
          title: 'Industry Events',
          link: IndustryEventList
        }
      ]
    },
    { title: 'Market Data Reports', link: MarketDataReportList }
  ];

  return menuItems.filter(item => !_.get(item, 'disabled', false));
};
