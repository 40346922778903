import { Formik } from 'formik';
import { Dimmer, Form, Header, Icon, Loader, Modal } from 'semantic-ui-react';

import { ChoiceToggle, Flex, FormRow, UpdateButtonInRow } from 'components';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import { industryEventsTogglePublished } from './sdk';

const TogglePublishedModal = ({ selectedEvents, onSuccess, onClose }) => {
  const handleSubmit = async (values, { setSubmitting }) => {
    const confirm = window.confirm(
      `Are you sure you want to confirm 'Published' status for selected events?`
    );

    if (!confirm) {
      setSubmitting(false);
      return;
    }

    setSubmitting(true);

    const { errors, success } = await industryEventsTogglePublished({
      is_published: values.is_published,
      industry_events: selectedEvents
    });

    if (success) {
      notifySuccess(`'Published' successfully changed.`);
      onSuccess();
    } else {
      notifyErrors(errors);
    }
    setSubmitting(false);
  };

  return (
    <Modal open={true} size="tiny" centered={false}>
      <Modal.Header>
        <Flex spaceBetween>
          <Header as="h2" style={{ margin: 0 }}>
            Toggle Published
          </Header>
          <Icon name="close" onClick={onClose} />
        </Flex>
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{ is_published: undefined }}
          onSubmit={handleSubmit}>
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <>
              <Form>
                <FormRow>
                  <ChoiceToggle
                    leftOption={{
                      predicate: values.is_published === true,
                      onClick: () => setFieldValue('is_published', true),
                      text: 'Publish'
                    }}
                    rightOption={{
                      predicate: values.is_published === false,
                      onClick: () => setFieldValue('is_published', false),
                      text: 'Unpublish'
                    }}
                  />
                </FormRow>

                <UpdateButtonInRow
                  onClick={handleSubmit}
                  subject="Published"
                  disabled={isSubmitting || values.is_published === undefined}
                />
              </Form>

              {isSubmitting && (
                <Dimmer active inverted>
                  <Loader inverted>Submitting</Loader>
                </Dimmer>
              )}
            </>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default TogglePublishedModal;
