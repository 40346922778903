import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import {
  Button,
  Filters as SharedFilters,
  Flex,
  Header,
  ListViewEssentials,
  LoginRequired,
  NoDataMessage,
  Page,
  Pagination
} from 'components';
import { pageUrl } from 'config/routes';
import { IndustryEventCreate } from 'pages';

import { industryEventList } from './sdk';
import { FILTERS as LAYOUT_FILTERS } from './utils';
import IndustryEventTable from './components/IndustryEventTable';

const IndustryEventList = ({
  user,
  data,
  limit,
  count,
  page,
  changePage,
  changeRowsPerPage,
  orderBy,
  sort,
  filters,
  refetch,
  history,
  clear,
  filterBy
}) => {
  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content>
          <Flex spaceBetween>
            <Header>Industry Events</Header>
            <Button
              size="large"
              onClick={() => history.push(pageUrl(IndustryEventCreate))}>
              Create Industry Event
            </Button>
          </Flex>
          <SharedFilters
            filters={filters}
            clearFilters={clear}
            filterBy={filterBy}
            count={count}
            layout={LAYOUT_FILTERS}
          />
          {!_.isEmpty(data) && (
            <IndustryEventTable
              sort={sort}
              orderBy={orderBy}
              industryEvents={data}
              filters={filters}
              refetch={refetch}
              count={count}
              pagination={
                <Pagination
                  limit={limit}
                  count={count}
                  page={page}
                  changePage={changePage}
                  changeRowsPerPage={changeRowsPerPage}
                />
              }
            />
          )}
          {_.isEmpty(data) && <NoDataMessage />}
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default ListViewEssentials({
  sdk: industryEventList
})(LoginRequired(withRouter(IndustryEventList)));
