import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { pageUrl } from 'config/routes';
import { IndustryEventList } from 'pages';
import IndustryEventForm from 'components/IndustryEventForm';

import { industryEventCreate } from './sdk';

const IndustryEventCreateForm = () => {
  const history = useHistory();

  const handleSubmit = useCallback(
    async (values, actions) => {
      const { setSubmitting, setFieldError } = actions;

      setSubmitting(true);
      const data = prepareFormData(values);

      const { errors, success } = await industryEventCreate(data);
      setSubmitting(false);

      if (success) {
        notifySuccess('Industry Event successfully created.');
        history.push(pageUrl(IndustryEventList));
        return;
      }

      handleFormErrors(errors, setFieldError);
    },
    [history]
  );

  const initialValues = {
    title: '',
    description: '',
    isPublished: false,
    startDate: null,
    endDate: null,
    image: null,
    externalUrl: '',
    topic: null,
    city: '',
    country: null
  };

  return (
    <IndustryEventForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    />
  );
};

export default IndustryEventCreateForm;
