import { Form } from 'semantic-ui-react';
import { Formik } from 'formik';

import {
  CountrySelect,
  CourseTopicSelect,
  CreateButtonInRow,
  DateField,
  Field,
  FileField,
  FormRow,
  InputField,
  RichTextAreaField,
  YesNoSelect
} from 'components';

import { IndustryEventSchema } from './schema';

const IndustryEventForm = ({ initialValues, handleSubmit }) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={IndustryEventSchema}
      onSubmit={handleSubmit}>
      {({ values, handleSubmit, isSubmitting, dirty }) => (
        <Form>
          <CreateButtonInRow
            isSubmitting={isSubmitting}
            dirty={dirty}
            onClick={handleSubmit}
          />
          <FormRow>
            <Field
              name="title"
              label="Title"
              component={InputField}
              placeholder="Title"
              width={4}
              required
            />
            <Field
              name="startDate"
              label="Start Date"
              component={DateField}
              width={4}
              required
            />
            <Field
              name="endDate"
              label="End Date"
              component={DateField}
              width={4}
              required
            />
            <Field
              name="isPublished"
              label="Is Published"
              component={YesNoSelect}
              width={4}
              required
            />
          </FormRow>
          <FormRow>
            <Field name="image" label="Image" component={FileField} width={4} />
            <Field
              name="externalUrl"
              label="External URL"
              component={InputField}
              placeholder="External URL"
              width={6}
            />
            <Field
              name="topic"
              label="Topic"
              component={CourseTopicSelect}
              width={3}
            />
            <Field
              name="city"
              label="City"
              component={InputField}
              placeholder="City"
              width={3}
            />
            <Field
              name="country"
              label="Country"
              component={CountrySelect}
              width={3}
            />
          </FormRow>
          <Field
            name="description"
            label="Description"
            component={RichTextAreaField}
          />
        </Form>
      )}
    </Formik>
  );
};

export default IndustryEventForm;
