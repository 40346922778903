import { BASE_URL } from 'sdk/urls';
import { requestSdk, get, post } from 'sdk';

export const industryEventDetail = async eventId => {
  const url = `${BASE_URL}/courses/industry/${eventId}/`;

  return await requestSdk(() => get(url));
};

export const industryEventDelete = async ({ itemId }) => {
  const url = `${BASE_URL}/courses/industry/${itemId}/delete/`;

  return await requestSdk(() => post(url));
};
