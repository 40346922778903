import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button, Header, Icon, Modal, Segment, Table } from 'semantic-ui-react';
import { Flex, SortableHeader } from 'components/index';
import NoDataMessage from 'components/NoDataMessage';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import usePolling from 'hooks/usePolling';
import { formatDateTime } from 'utils';
import { emailLogStatistic, emailLogStatisticLocationLookup } from './sdk';
import { pluralize } from 'utils/string';
import { useSortable } from 'utils/sort';
import EmailLogStatisticIPAggregationModal from './EmailLogStatisticIPAggregationModal';
import { TrackedBackIDsCell } from './TrackedBackIDsCell';

function TableWrapper({ children }) {
  return (
    <div
      style={{
        maxHeight: '600px',
        overflowY: 'auto',
        display: 'block',
        width: '100%',
        marginTop: '14px'
      }}>
      {children}
    </div>
  );
}

const EnabledTriggerButton = ({ onClick }) => {
  return (
    <Button color="blue" onClick={onClick}>
      View
    </Button>
  );
};

// We need a "Disabled" component, rather than just a disabled button,
// because `disabled` adds opacity, which doesn't play nicely with our sticky header,
// so we mimic the disabled state with a custom style
const DisabledTriggerButton = () => {
  return (
    <Button
      color="blue"
      style={{ backgroundColor: 'rgba(33, 133, 208, 0.4)', cursor: 'default' }}>
      View
    </Button>
  );
};

const EmailLogStatisticModal = ({ logType, log, onLocationLookupSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locationLookupLoading, setLocationLookupLoading] = useState(false);

  const statisticsRef = useRef(statistics);

  const { data, sort, handleSortUpdate } = useSortable({
    defaultData: statistics,
    caseInsensitive: true
  });

  useEffect(() => {
    // We need to keep the statistics in a ref to be able to compare them inside the polling callback
    // else the callback will always see the initial statistics state
    statisticsRef.current = statistics;
  }, [statistics]);

  const disableLocationLookupButton = useMemo(
    () =>
      statistics.length === 0 ||
      statistics.every(statistic => statistic.location !== ''),
    [statistics]
  );

  const fetchEmailLogStatistics = useCallback(async () => {
    setModalOpen(true);
    setLoading(true);
    const { data, errors, success } = await emailLogStatistic(logType, log.id);

    if (success) {
      setStatistics(data.statistics);
      setSummary(data.summary);
    } else {
      notifyErrors(errors);
    }
    setLoading(false);
  }, [logType, log]);

  const handleLocationLookup = async () => {
    setLocationLookupLoading(true);
    const { errors, success } = await emailLogStatisticLocationLookup(
      logType,
      log.id
    );

    if (success) {
      notifySuccess(
        'Location lookup has started. The data will refresh automatically.'
      );
      startPolling();
    } else {
      notifyErrors(errors);
    }
  };

  const pollingCallback = async () => {
    await fetchEmailLogStatistics();

    const currentStatistics = statisticsRef.current;

    if (currentStatistics.every(statistic => statistic.location !== '')) {
      stopPolling();
      onLocationLookupSuccess?.();
    }
  };

  const { startPolling, stopPolling } = usePolling({
    callback: pollingCallback,
    delay: 3000
  });

  return (
    <Modal
      open={modalOpen}
      size="large"
      centered={false}
      trigger={
        log.statistic_logs_count > 0 ? (
          <EnabledTriggerButton onClick={fetchEmailLogStatistics} />
        ) : (
          <DisabledTriggerButton />
        )
      }>
      <Modal.Header>
        <Flex spaceBetween>
          <Header as="h2" style={{ margin: 0 }}>
            Log Statistic
          </Header>
          <Icon name="close" onClick={() => setModalOpen(false)} />
        </Flex>
      </Modal.Header>
      <Modal.Content>
        <>
          {!loading && data.length > 0 && (
            <Segment>
              <Header as="div" size="medium">
                Mail Read Information
              </Header>
              <div>
                <div>
                  <strong>To:</strong> {log.email}
                </div>
                <div>
                  <strong>Subject:</strong> {log.subject}
                </div>
                <div>
                  <strong>Sent on:</strong> {formatDateTime(log.sent_date)}
                </div>
                <div>
                  <strong>First Opened:</strong>{' '}
                  {formatDateTime(statistics[statistics.length - 1].opened_at)}
                </div>
                <div>
                  <strong>Last Opened:</strong>{' '}
                  {formatDateTime(statistics[0].opened_at)}
                </div>
              </div>
            </Segment>
          )}
          {!loading && summary && (
            <Segment>
              <Header as="div" size="medium">
                Tracking Summary
              </Header>
              <div>
                Opened{' '}
                <strong>
                  {summary.total_opened}{' '}
                  {pluralize('time', summary.total_opened)}
                </strong>{' '}
                on{' '}
                {summary.desktop_devices_count > 0 && (
                  <strong
                    style={{
                      color:
                        summary.desktop_devices_count > 1 ? 'red' : 'inherit'
                    }}>
                    {summary.desktop_devices_count}{' '}
                    {pluralize('desktop', summary.desktop_devices_count)}
                  </strong>
                )}
                {summary.desktop_devices_count > 0 &&
                summary.mobile_devices_count > 0
                  ? ', '
                  : ' '}
                {summary.mobile_devices_count > 0 && (
                  <strong
                    style={{
                      color:
                        summary.mobile_devices_count > 1 ? 'red' : 'inherit'
                    }}>
                    {summary.mobile_devices_count}{' '}
                    {pluralize('mobile', summary.mobile_devices_count)}{' '}
                  </strong>
                )}
                {summary.locations_count > 0 && (
                  <>
                    in{' '}
                    <strong
                      style={{
                        color: summary.locations_count > 1 ? 'red' : 'inherit'
                      }}>
                      {summary.locations_count}{' '}
                      {pluralize('location', summary.locations_count)}
                    </strong>
                  </>
                )}
              </div>
            </Segment>
          )}
          {!loading && data.length > 0 && (
            <Segment>
              <Flex flexEnd>
                <EmailLogStatisticIPAggregationModal
                  logType={logType}
                  log={log}
                />
                <Button
                  primary
                  onClick={handleLocationLookup}
                  disabled={
                    locationLookupLoading || disableLocationLookupButton
                  }>
                  Get Location
                </Button>
              </Flex>
              <TableWrapper>
                <Table celled selectable>
                  <Table.Header
                    style={{ position: 'sticky', zIndex: 1, top: 0 }}>
                    <Table.Row>
                      <SortableHeader
                        sort={sort}
                        orderBy={handleSortUpdate}
                        field="opened_at">
                        Opened At
                      </SortableHeader>
                      <Table.HeaderCell>IP Address</Table.HeaderCell>
                      <Table.HeaderCell>Tracked Back IDs</Table.HeaderCell>
                      <SortableHeader
                        sort={sort}
                        orderBy={handleSortUpdate}
                        field="browser">
                        Browser
                      </SortableHeader>
                      <SortableHeader
                        sort={sort}
                        orderBy={handleSortUpdate}
                        field="operating_system">
                        Operating System
                      </SortableHeader>
                      <SortableHeader
                        sort={sort}
                        orderBy={handleSortUpdate}
                        field="device">
                        Device
                      </SortableHeader>
                      <SortableHeader
                        sort={sort}
                        orderBy={handleSortUpdate}
                        field="location">
                        Location
                      </SortableHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.map((log, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{formatDateTime(log.opened_at)}</Table.Cell>
                        <Table.Cell>{log.ip_address}</Table.Cell>
                        <TrackedBackIDsCell
                          trackedBackIDs={log.tracked_back_ids}
                        />
                        <Table.Cell>{log.browser}</Table.Cell>
                        <Table.Cell>{log.operating_system}</Table.Cell>
                        <Table.Cell>{log.device}</Table.Cell>
                        <Table.Cell>{log.location}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </TableWrapper>
            </Segment>
          )}
          {!loading && statistics.length === 0 && <NoDataMessage />}
        </>
      </Modal.Content>
    </Modal>
  );
};

export default EmailLogStatisticModal;
