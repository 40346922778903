import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header, Loader, Segment } from 'semantic-ui-react';

import { EDITION_WARNING_CONTENT_TYPES } from 'domainConstants';
import {
  ContentEditionWarning,
  DeleteModal,
  LoginRequired,
  Page
} from 'components';
import { notifyErrors } from 'utils/notifications';
import { IndustryEventList } from 'pages';

import { industryEventDelete, industryEventDetail } from './sdk';
import IndustryEventUpdateForm from './components/IndustryEventUpdateForm';

const IndustryEventDetail = ({ user, crumbs }) => {
  const [industryEvent, setIndustryEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const { eventId } = useParams();

  const fetchIndustryEvent = useCallback(async () => {
    setLoading(true);
    const { data, errors, success } = await industryEventDetail(eventId);

    if (success) {
      setIndustryEvent(data);
    } else {
      notifyErrors(errors);
    }
    setLoading(false);
  }, [eventId]);

  useEffect(() => {
    fetchIndustryEvent();
  }, [fetchIndustryEvent]);

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          {industryEvent && (
            <ContentEditionWarning
              user={user}
              contentType={EDITION_WARNING_CONTENT_TYPES.COURSE}
              contentId={industryEvent.id}
            />
          )}

          <Header as="h1">Edit Industry Event</Header>

          <Segment>
            {!loading && industryEvent && (
              <>
                <IndustryEventUpdateForm
                  industryEvent={industryEvent}
                  fetchIndustryEvent={fetchIndustryEvent}
                />
                {user.is_superuser && (
                  <Segment color="red">
                    <Header as="h3">Dangerous Actions</Header>
                    <DeleteModal
                      itemId={industryEvent.id}
                      objName={industryEvent.title}
                      deleteButtonText="Delete Event"
                      sdk={industryEventDelete}
                      successRedirect={IndustryEventList}
                    />
                  </Segment>
                )}
              </>
            )}
            {loading && <Loader active />}
          </Segment>
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default LoginRequired(IndustryEventDetail);
