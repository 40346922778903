import { Header, Segment } from 'semantic-ui-react';
import { Page, LoginRequired } from 'components';
import { IndustryEventCreateForm } from './components';

const IndustryEventCreate = ({ user, crumbs }) => {
  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Segment>
            <Header as="h1">Create Industry Event</Header>
            <IndustryEventCreateForm />
          </Segment>
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default LoginRequired(IndustryEventCreate);
