import { useState } from 'react';
import { Button, Header, Label, Modal } from 'semantic-ui-react';

import { Tooltip } from 'components';

import { DeleteEventModal, TogglePublishedModal } from './components';

const LIMITS = {
  DELETE_EVENTS: 100,
  TOGGLE_PUBLISHED: 200
};

const BulkActionsModal = ({ selectedEvents, updateOnSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenTogglePublishedModal, setIsOpenTogglePublishedModal] = useState(
    false
  );

  const toggleIsOpenDeleteEventModal = () =>
    setIsOpenDeleteModal(prevState => !prevState);

  const toggleIsOpenTogglePublishedModal = () =>
    setIsOpenTogglePublishedModal(prevState => !prevState);

  const title = 'Bulk actions for selected events';

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        size="tiny"
        trigger={
          <Button
            color="green"
            style={{ backgroundColor: 'rgb(36, 214, 163)' }}
            onClick={() => setModalOpen(true)}
            disabled={!selectedEvents.length}>
            {title}
          </Button>
        }>
        <Header content={title} />

        <Modal.Content>
          <Label color="blue" basic size="large">
            Selected events: {selectedEvents.length}
          </Label>
          <Tooltip
            disabled={selectedEvents.length <= LIMITS.TOGGLE_PUBLISHED}
            on="hover"
            position="bottom center"
            content={`You can change up to ${LIMITS.TOGGLE_PUBLISHED} events at once`}
            trigger={
              <div style={{ margin: '15px 0' }}>
                <Button
                  fluid
                  color="green"
                  style={{
                    fontSize: '16px',
                    backgroundColor: 'rgb(36, 214, 163)'
                  }}
                  disabled={selectedEvents.length > LIMITS.TOGGLE_PUBLISHED}
                  onClick={toggleIsOpenTogglePublishedModal}>
                  Toggle Published
                </Button>
              </div>
            }
          />
          <Tooltip
            disabled={selectedEvents.length <= LIMITS.DELETE_EVENTS}
            on="hover"
            position="bottom center"
            content={`You can delete up to ${LIMITS.DELETE_EVENTS} events at once`}
            trigger={
              <div style={{ margin: '15px 0' }}>
                <Button
                  fluid
                  color="red"
                  style={{ fontSize: '16px' }}
                  disabled={selectedEvents.length > LIMITS.DELETE_EVENTS}
                  onClick={toggleIsOpenDeleteEventModal}>
                  Delete events
                </Button>
              </div>
            }
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" inverted onClick={() => setModalOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
      {isOpenTogglePublishedModal && (
        <TogglePublishedModal
          selectedEvents={selectedEvents}
          onSuccess={data => {
            toggleIsOpenTogglePublishedModal();
            setModalOpen(false);
            updateOnSuccess(data);
          }}
          onClose={() => {
            toggleIsOpenTogglePublishedModal();
          }}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteEventModal
          selectedEvents={selectedEvents}
          isOpen={isOpenDeleteModal}
          onSuccess={data => {
            toggleIsOpenDeleteEventModal();
            setModalOpen(false);
            updateOnSuccess(data);
          }}
          onClose={() => {
            toggleIsOpenDeleteEventModal();
          }}
        />
      )}
    </>
  );
};

export default BulkActionsModal;
